import { post } from "@/application/api";
import { AxiosResponse } from "axios";

export interface PasswordForgottenResponse {
  data: {
    message: string;
  };
}

export const passwordForgotten = (
  email: string
): Promise<AxiosResponse<PasswordForgottenResponse>> => {
  return post("password/forgotten", {
    email,
  });
};
