
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { passwordForgotten } from "@/modules/authorisation/api/forgotten";
import Vue, { VueConstructor } from "vue";
import KBtn from "@/components/KBtn.vue";

interface ComponentData {
  alertType: string;
  alertMessage: string;
  isLoading: boolean;
  isValid: boolean;
  form: {
    email: string;
  };
}

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: {
          validate: () => void;
        };
      };
    }
  >
).extend({
  name: "PasswordForgotten",
  components: {
    KBtn,
    KFieldGroup,
    KTextField,
  },
  data: (): ComponentData => ({
    alertType: "success",
    alertMessage: "",
    isLoading: false,
    isValid: false,
    form: {
      email: "",
    },
  }),
  created() {
    if (typeof this.$route.query.email === "string") {
      this.form.email = this.$route.query.email;
    }
  },
  methods: {
    async handlePasswordForgotten(): Promise<void> {
      this.$refs.form.validate();

      if (!this.isValid) return;

      this.isLoading = true;
      this.alertMessage = "";
      this.alertType = "error";

      try {
        await passwordForgotten(this.form.email);
        this.alertMessage = this.$t(
          "authorisation.passwordForgotten.successMessage"
        ) as string;
        this.alertType = "success";
      } catch (e) {
        this.alertMessage = this.$t("errors.422") as string;
        this.$refs.form.validate();
      } finally {
        this.isLoading = false;
      }
    },
  },
});
